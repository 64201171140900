import { cn } from '$app/utils'
import RedirectPrompt from '$blocks/RedirectPrompt'
import Breadcrumb from '$components/Breadcrumb/v2'
import FooterAction from '$components/FooterAction'
import Loading from '$components/Loading'
import PasswordPolicyChecker from '$components/PasswordPolicyChecker'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import { usePasswordPolicyService, useUserService } from '$hooks/services'
import { checkPolicies } from '$pages/PasswordPolicy/constants'
import { redirect, ROUTE_NAMES } from '$router/config'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { Password } from '@genie-fintech/ui/components/fields'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useMount } from 'ahooks'
import { ChangeEvent, FormEvent, useCallback, useState } from 'react'

const { colors } = themeVars

const AppUserUpdatePassword = () => {
  const [updatedPwd, setUpdatedPwd] = useState('')

  const [errorMsg, setErrorMsg] = useState('')

  const {
    route: { params }
  } = useRouteSummary()

  const { userId } = params

  const proxyRedirect = useRedirectProxy()

  const { fetchPasswordPolicy, passwordPolicy, fetchingPasswordPolicy } =
    usePasswordPolicyService()

  const { updatePasswordAsync, updatingPassword } = useUserService()

  const enabled_policies = passwordPolicy.filter(d => d.is_enabled)

  const isPwdValid = checkPolicies(enabled_policies, updatedPwd)

  useMount(() => fetchPasswordPolicy())

  const onChangePassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (errorMsg) setErrorMsg('')
      setUpdatedPwd(e.currentTarget.value)
    },
    [errorMsg]
  )

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_USER_DETAIL, { params })
  }, [proxyRedirect, params])

  const onSuccess = useCallback(() => {
    redirect(ROUTE_NAMES.APP_USER_DETAIL, { params })
  }, [params])

  const onSaveChanges = useCallback(() => {
    if (!userId) return

    if (!isPwdValid) {
      setErrorMsg('Password must be valid with all password policies')
      return
    }

    return updatePasswordAsync({
      isAdmin: false,
      userId,
      password: updatedPwd
    }).then(onSuccess)
  }, [isPwdValid, userId, updatePasswordAsync, updatedPwd, onSuccess])

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      onSaveChanges()
    },
    [onSaveChanges]
  )

  if (fetchingPasswordPolicy) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_USERS },
          { name: 'User Details' }
        ]}
      />

      <form
        onSubmit={onSubmit}
        className={cn(
          'flex max-w-[1056px] mx-auto px-12 py-7 rounded-lg',
          defaultBackground,
          defaultBorder
        )}
      >
        <article className="grid md:grid-cols-2 gap-16">
          <article className="flex flex-col gap-1">
            <p className="font-semibold">PASSWORD</p>
            <p className="text-xs" style={{ color: colors.neutral[50] }}>
              Update or reset your password to maintain account security and
              access control.
            </p>
          </article>

          <article className="flex flex-col gap-6">
            <Password
              label="Update New Password"
              error={!!errorMsg}
              message={errorMsg}
              inputProps={{
                value: updatedPwd,
                onChange: onChangePassword
              }}
            />

            <PasswordPolicyChecker
              pwd={updatedPwd}
              policies={enabled_policies}
            />
          </article>
        </article>

        <FooterAction>
          <article className="flex justify-end w-full max-w-[1056px] gap-2 mx-auto">
            <Button
              disabled={updatingPassword}
              styleVariants={{ type: 'text' }}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button type="submit" disabled={!updatedPwd || updatingPassword}>
              {updatingPassword && <Spinner />}
              Save Changes
            </Button>
          </article>
        </FooterAction>
      </form>

      <RedirectPrompt
        type="edit"
        isDirty={!!updatedPwd}
        isValid={isPwdValid}
        onConfirm={() => {
          return new Promise(resolve => {
            onSaveChanges()?.then(resolve)
          })
        }}
      />
    </>
  )
}

export default AppUserUpdatePassword
