import Breadcrumb from '$components/Breadcrumb/v2'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppUserService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import UserInfo from './UserInfo'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import DeleteWithConfirmInput from '$components/DeleteWithConfirmInput'
import { cn } from '$app/utils'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { KeyRound } from 'lucide-react'
import { Button } from '@genie-fintech/ui/components'
import { useCallback } from 'react'
import GenerateResetLink from '$components/GenerateResetLink'

const { colors } = themeVars

const AppUserDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, userId } = params

  const { user, fetchAppUserDetail, fetchingAppUserDetail } =
    useAppUserService()

  useMount(() => {
    if (!appId || !userId) return
    fetchAppUserDetail({
      application_id: appId,
      user_id: userId
    })
  })

  const onUpdatePassword = useCallback(() => {
    redirect(ROUTE_NAMES.APP_USER_UPDATE_PASSWORD, { params })
  }, [params])

  if (fetchingAppUserDetail || !user) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_USERS },
          { name: 'User Details' }
        ]}
      />

      <article className="flex flex-col gap-2">
        <UserInfo user={user} />

        <article
          className={cn(
            'flex px-12 py-7 rounded-lg',
            defaultBackground,
            defaultBorder
          )}
        >
          <article className="grid md:grid-cols-2 gap-16">
            <article className="flex flex-col gap-1">
              <p className="font-semibold">UPDATE OR RESET PASSWORD</p>
              <p className="text-xs" style={{ color: colors.neutral[50] }}>
                Update or reset your password to maintain account security and
                access control.
              </p>
            </article>

            <article className="flex flex-col gap-3 min-w-0">
              <article
                className="flex gap-0.5 items-center"
                style={{ color: colors.text.light }}
              >
                <KeyRound size={16} />
                <span className="text-sm font-medium">
                  The password is encrypted and can not be seen.
                </span>
              </article>

              <Button
                styleVariants={{ kind: 'neutral', type: 'outlined' }}
                onClick={onUpdatePassword}
              >
                Update Password
              </Button>

              <p
                className="font-semibold text-center"
                style={{ color: colors.text.disabled }}
              >
                OR
              </p>

              <GenerateResetLink
                appId={appId}
                userId={user.id}
                reset_link_expired_at={user.password_reset_link_expired_in}
              />
            </article>
          </article>
        </article>

        <DangerZone>
          <DeleteWithConfirmInput type="user" name={user.name} />
        </DangerZone>
      </article>
    </>
  )
}

export default AppUserDetail
