import { URLPattern } from 'urlpattern-polyfill'
import { CreateHistory, utility } from 'rutter'

export const { buildURL } = utility

export enum ROUTE_NAMES {
  LOGIN = 'login',
  INDEX = 'index',
  APPS = 'apps',
  APP_CREATE = 'app-create',
  APP_HOME = 'app-home',
  APP_BASIC_SETTING = 'app-basic-setting',
  APP_ADVANCED_SETTING = 'app_advance_setting',
  APP_LAUNCHPAD_SETTING = 'app_launchpad_setting',
  APP_TOKEN_SETTING = 'app_token_setting',
  APP_GROUPS = 'app_groups',
  APP_GROUP_DETAIL = 'app_group_detail',
  APP_GROUP_CREATE = 'app_group_create',
  APP_GROUP_EDIT = 'app_group_edit',
  APP_ROLES = 'app_roles',
  APP_ROLE_DETAIL = 'app_role_detail',
  APP_ROLES_CSV_IMPORT = 'app_roles_csv_import',
  APP_ROLE_CREATE = 'app_role_create',
  APP_ROLE_EDIT = 'app_role_edit',
  APP_USERS = 'app_users',
  APP_USER_DETAIL = 'app_user_detail',
  APP_USERS_CSV_IMPORT = 'app_users_csv_import',
  APP_USER_CREATE = 'app_user_create',
  APP_USER_EDIT = 'app_user_edit',
  APP_USER_UPDATE_PASSWORD = 'app_user_update_password',
  APP_PERMISSIONS = 'app_permissions',
  APP_PERMISSION_DETAIL = 'app_permission_detail',
  APP_PERMISSIONS_CSV_IMPORT = 'app_permissions_csv_import',
  APP_PERMISSION_CREATE = 'app_permission_create',
  APP_PERMISSION_EDIT = 'app_permission_edit',
  GLOBAL_SETTING = 'global_setting',
  PASSWORD_POLICY = 'password_policy',
  COUNTRIES = 'countries',
  COUNTRY_CREATE = 'country_create',
  COUNTRY_EDIT = 'country_edit',
  GLOBAL_APP_USERS = 'global_app_users',
  GLOBAL_APP_USER_ATTACH_APP = 'global_app_user_attach_app',
  GLOBAL_APP_USER_DETAIL = 'global_app_user_detail',
  GLOBAL_DASHBOARD_USERS = 'global_dashboard_users',
  GLOBAL_DASHBOARD_USER_ATTACH_APP = 'global_dashboard_user_attach_app',
  GLOBAL_DASHBOARD_USER_CREATE = 'global_dashboard_user_create',
  GLOBAL_DASHBOARD_USER_DETAIL = 'global_dashboard_user_detail'
}

export const {
  summaryState,
  routeState,
  watchRouteState,
  watchSummaryState,
  on,
  onOneOf,
  redirect,
  getDetail,
  onRouteMatch
} = new CreateHistory({
  URLPattern,
  routes: {
    [ROUTE_NAMES.INDEX]: {
      pathname: '/',
      meta: {
        title: ''
      }
    },
    [ROUTE_NAMES.APPS]: {
      pathname: '/apps',
      meta: {
        title: 'Apps'
      }
    },
    [ROUTE_NAMES.APP_CREATE]: {
      pathname: '/apps/create',
      meta: {
        title: 'Create App'
      }
    },
    [ROUTE_NAMES.APP_HOME]: {
      pathname: '/apps/:appId/home',
      meta: {
        title: 'Home'
      }
    },
    [ROUTE_NAMES.APP_BASIC_SETTING]: {
      pathname: '/apps/:appId/basic-setting',
      meta: {
        title: 'Basic Setting'
      }
    },
    [ROUTE_NAMES.APP_ADVANCED_SETTING]: {
      pathname: '/apps/:appId/advance-setting',
      meta: {
        title: 'Advance Setting'
      }
    },
    [ROUTE_NAMES.APP_LAUNCHPAD_SETTING]: {
      pathname: '/apps/:appId/launchpad-setting',
      meta: {
        title: 'Launchpad Setting'
      }
    },
    [ROUTE_NAMES.APP_TOKEN_SETTING]: {
      pathname: '/apps/:appId/token-setting',
      meta: {
        title: 'Token Setting'
      }
    },
    [ROUTE_NAMES.APP_GROUPS]: {
      pathname: '/apps/:appId/groups',
      meta: {
        title: 'Groups'
      }
    },
    [ROUTE_NAMES.APP_GROUP_CREATE]: {
      pathname: '/apps/:appId/groups/create',
      meta: {
        title: 'Create Group'
      }
    },
    [ROUTE_NAMES.APP_GROUP_DETAIL]: {
      pathname: '/apps/:appId/groups/:groupId',
      meta: {
        title: 'Group Detail'
      }
    },
    [ROUTE_NAMES.APP_GROUP_EDIT]: {
      pathname: '/apps/:appId/groups/:groupId/edit',
      meta: {
        title: 'Edit Group'
      }
    },
    [ROUTE_NAMES.APP_USERS]: {
      pathname: '/apps/:appId/users',
      meta: {
        title: 'Users'
      }
    },
    [ROUTE_NAMES.APP_USERS_CSV_IMPORT]: {
      pathname: '/apps/:appId/users/import',
      meta: {
        title: 'Import Users'
      }
    },
    [ROUTE_NAMES.APP_USER_CREATE]: {
      pathname: '/apps/:appId/users/create',
      meta: {
        title: 'Create User'
      }
    },
    [ROUTE_NAMES.APP_USER_DETAIL]: {
      pathname: '/apps/:appId/users/:userId',
      meta: {
        title: 'User Detail'
      }
    },
    [ROUTE_NAMES.APP_USER_EDIT]: {
      pathname: '/apps/:appId/users/:userId/edit',
      meta: {
        title: 'Edit User'
      }
    },
    [ROUTE_NAMES.APP_USER_UPDATE_PASSWORD]: {
      pathname: '/apps/:appId/users/:userId/update-password',
      meta: {
        title: 'Update Password'
      }
    },
    [ROUTE_NAMES.APP_ROLES]: {
      pathname: '/apps/:appId/roles',
      meta: {
        title: 'Roles'
      }
    },
    [ROUTE_NAMES.APP_ROLES_CSV_IMPORT]: {
      pathname: '/apps/:appId/roles/import',
      meta: {
        title: 'Import Roles'
      }
    },
    [ROUTE_NAMES.APP_ROLE_CREATE]: {
      pathname: '/apps/:appId/roles/create',
      meta: {
        title: 'Create Role'
      }
    },
    [ROUTE_NAMES.APP_ROLE_DETAIL]: {
      pathname: '/apps/:appId/roles/:roleId',
      meta: {
        title: 'Role Detail'
      }
    },
    [ROUTE_NAMES.APP_ROLE_EDIT]: {
      pathname: '/apps/:appId/roles/:roleId/edit',
      meta: {
        title: 'Edit Role'
      }
    },
    [ROUTE_NAMES.APP_PERMISSIONS]: {
      pathname: '/apps/:appId/permissions',
      meta: {
        title: 'Permissions'
      }
    },
    [ROUTE_NAMES.APP_PERMISSIONS_CSV_IMPORT]: {
      pathname: '/apps/:appId/permissions/import',
      meta: {
        title: 'Import Permissions'
      }
    },
    [ROUTE_NAMES.APP_PERMISSION_CREATE]: {
      pathname: '/apps/:appId/permissions/create',
      meta: {
        title: 'Create Permission'
      }
    },
    [ROUTE_NAMES.APP_PERMISSION_DETAIL]: {
      pathname: '/apps/:appId/permissions/:permissionId',
      meta: {
        title: 'Permission Detail'
      }
    },
    [ROUTE_NAMES.APP_PERMISSION_EDIT]: {
      pathname: '/apps/:appId/permissions/:permissionId/edit',
      meta: {
        title: 'Edit Permission'
      }
    },
    [ROUTE_NAMES.GLOBAL_SETTING]: {
      pathname: '/global-setting',
      meta: {
        title: 'Global Setting'
      }
    },
    [ROUTE_NAMES.PASSWORD_POLICY]: {
      pathname: '/global-setting/password-policy',
      meta: {
        title: 'Global Setting'
      }
    },
    [ROUTE_NAMES.COUNTRIES]: {
      pathname: '/global-setting/countries',
      meta: {
        title: 'Countries'
      }
    },
    [ROUTE_NAMES.COUNTRY_CREATE]: {
      pathname: '/global-setting/countries/create',
      meta: {
        title: 'Create Country'
      }
    },
    [ROUTE_NAMES.COUNTRY_EDIT]: {
      pathname: '/global-setting/countries/:countryId',
      meta: {
        title: 'Edit Country'
      }
    },
    [ROUTE_NAMES.GLOBAL_APP_USERS]: {
      pathname: '/application-users',
      meta: {
        title: 'Application Users'
      }
    },
    [ROUTE_NAMES.GLOBAL_APP_USER_DETAIL]: {
      pathname: '/application-users/:appUserId',
      meta: {
        title: 'Application User Detail'
      }
    },
    [ROUTE_NAMES.GLOBAL_APP_USER_ATTACH_APP]: {
      pathname: '/application-users/:appUserId/attach-app',
      meta: {
        title: 'Attach Application'
      }
    },
    [ROUTE_NAMES.GLOBAL_DASHBOARD_USERS]: {
      pathname: '/dashboard-users',
      meta: {
        title: 'Dashboard Users'
      }
    },
    [ROUTE_NAMES.GLOBAL_DASHBOARD_USER_CREATE]: {
      pathname: '/dashboard-users/create',
      meta: {
        title: 'Create Dashboard User'
      }
    },
    [ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL]: {
      pathname: '/dashboard-users/:dashboardUserId',
      meta: {
        title: 'Dashboard User Detail'
      }
    },
    [ROUTE_NAMES.GLOBAL_DASHBOARD_USER_ATTACH_APP]: {
      pathname: '/dashboard-users/:dashboardUserId/attach-app',
      meta: {
        title: 'Attach Application'
      }
    },
    [ROUTE_NAMES.LOGIN]: {
      pathname: '/login',
      meta: {
        title: 'Login'
      }
    }
  }
})

// Scroll to top on page navigate
watchRouteState(({ hash }) => {
  if (hash) return

  setTimeout(() => {
    self.scrollTo({ top: 0 })
  }, 300)
})

type RedirectParams = Parameters<typeof redirect>

export type RouteNames = RedirectParams[0]
export type RedirectOptions = RedirectParams[1]
