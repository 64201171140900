import { postUpdatePassword } from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useUserService = () => {
  const { runAsync: updatePasswordAsync, loading: updatingPassword } =
    useRequest(postUpdatePassword, {
      manual: true,
      onSuccess: () => {
        toast.success('Password Updated Successfully!')
      }
    })

  return {
    updatePasswordAsync,
    updatingPassword
  }
}
