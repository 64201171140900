import AppCard from '$components/AppCard'
import Breadcrumb from '$components/Breadcrumb/v2'
import EmptyData from '$components/EmptyData'
import Loading from '$components/Loading'
import ModKey from '$components/ModKey'
import { useRedirectProxy } from '$contexts/RouteContext/hooks'
import { useApiListingParams, useFocusEventLister } from '$hooks/actions'
import { useAppService } from '$hooks/services'
import { ROUTE_NAMES } from '$router/config'
import { TAppListResponse } from '$services/api'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { Icon } from '@genie-fintech/ui/icons'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useInViewport } from 'ahooks'
import { useCallback, useEffect, useRef, useState } from 'react'

const { colors } = themeVars

const PER_PAGE = 24

const AppList = () => {
  const [appList, setAppList] = useState<TAppListResponse['data']>([])

  const [page, setPage] = useState(1)

  const redirect = useRedirectProxy()

  const isMounted = useIsMounted()

  const {
    fetchAppListAsync,
    fetchingAppList,
    app: { meta }
  } = useAppService()

  const { last_page = 1 } = meta ?? {}

  const targetRef = useRef(null)

  const inputRef = useRef<HTMLInputElement>(null)

  const { searchValue, updateSearchValue, debouncedSearchValue } =
    useApiListingParams({ total: meta?.total, perPage: PER_PAGE })

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateSearchValue(e.currentTarget.value)
    },
    [updateSearchValue]
  )

  useInViewport(targetRef, {
    callback: entry => {
      if (fetchingAppList) return

      if (entry.isIntersecting) {
        setPage(prev => Math.min(prev + 1, last_page))
      }
    }
  })

  useEffect(() => {
    setAppList([])
    setPage(1)
  }, [debouncedSearchValue])

  useEffect(() => {
    fetchAppListAsync({
      page,
      per_page: PER_PAGE,
      q: debouncedSearchValue
    }).then(({ data }) => setAppList(prev => [...prev, ...data]))
  }, [fetchAppListAsync, page, debouncedSearchValue])

  useFocusEventLister(() => {
    inputRef.current?.focus()
  })

  const onCreateApp = useCallback(() => {
    redirect(ROUTE_NAMES.APP_CREATE)
  }, [redirect])

  if (!isMounted) return null

  return (
    <>
      <Breadcrumb category={ROUTE_NAMES.APPS} />

      <section className="flex flex-1 flex-col relative gap-y-4 max-w-[1144px] mx-auto">
        <header className="flex flex-wrap items-end justify-between gap-2">
          <article className="flex flex-col">
            <p
              className="text-[32px] font-semibold"
              style={{ color: colors.text.neutral }}
            >
              {`Applications ${meta?.total ? `- ${meta.total}` : ''}`}
            </p>

            <p
              className="text-sm font-medium"
              style={{ color: colors.text.disabled }}
            >
              Setup a mobile, web or IoT application to use Auth0 for
              Authentication.
            </p>
          </article>

          <article className="flex gap-x-4 items-center">
            <BaseText
              inputRef={inputRef}
              containerProps={{ className: 'min-w-[250px]' }}
              affix={{
                pre: <Icon namespace="Search" width={16} />,
                post: searchValue ? (
                  <button onClick={() => updateSearchValue('')}>
                    <Icon namespace="Cross" width={18} />
                  </button>
                ) : (
                  <p className="flex gap-x-1 items-center text-[--colors-text-disabled] text-sm">
                    <span>
                      <ModKey />
                    </span>
                    <span>K</span>
                  </p>
                )
              }}
              inputProps={{
                type: 'text',
                value: searchValue,
                onChange: handleOnChange,
                placeholder: 'Search applications...'
              }}
            />

            <Button onClick={onCreateApp}>
              <Icon namespace="Add" />
              <span className="whitespace-nowrap">Create Application</span>
            </Button>
          </article>
        </header>

        <main className="flex-1 flex flex-col relative">
          {!appList.length && fetchingAppList && <Loading />}

          {!appList.length && !fetchingAppList && (
            <article className="flex-1 grid place-items-center">
              <EmptyData type="application" onClick={onCreateApp} />
            </article>
          )}

          <article className="grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {appList.map((v, k) => {
              return <AppCard data={v} key={k} />
            })}
          </article>

          {appList.length !== meta?.total && !!appList.length && (
            <article className="flex justify-center py-20">
              <article ref={targetRef} className="flex justify-center gap-x-2">
                <Spinner />
              </article>
            </article>
          )}
        </main>
      </section>
    </>
  )
}

export default AppList
