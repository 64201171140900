import clsx, { ClassValue } from 'clsx'
import { APP_TITLE } from './services/environments'
import { twMerge } from 'tailwind-merge'
import { configUpload } from '$services/api'
import axios from 'axios'
import { generate } from 'generate-password-browser'
import { format, isValid } from 'date-fns'

export const setLocalStorage = (key: string, value: unknown) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStorage = <T>(key: string): T | undefined => {
  const storedValue = localStorage.getItem(key)
  return storedValue ? JSON.parse(storedValue) : undefined
}

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}

export type TypeAppTitle = {
  title?: string
  suffix?: string
  addSuffix?: boolean
  returnAppTitle?: boolean
}

export const getAppTitle = (options: TypeAppTitle = {}): string => {
  const {
    title,
    suffix = APP_TITLE,
    addSuffix = true,
    returnAppTitle = true
  } = options

  if (title === undefined && returnAppTitle) {
    return APP_TITLE
  }

  if (addSuffix) {
    return `${title} - ${suffix}`
  }

  return title || ''
}

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

export type TFileUploadData = {
  file_name: string
  key: string
  file: File
  preview: string
}

export const uploadFile = async (file: File): Promise<TFileUploadData> => {
  try {
    const { url, key, preview } = await configUpload()

    await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
        Accept: 'application/json'
      }
    })

    return {
      file_name: file.name.split('.').slice(0, -1).join('.'), // remove extension
      key,
      file,
      preview
    }
  } catch (error) {
    console.error(error)

    return {
      file_name: '',
      key: '',
      file,
      preview: ''
    }
  }
}

export const formatFileSize = (size: number) => {
  const fileSizeUnit = ['KB', 'MB', 'GB', 'TB']

  let value = size ?? 0

  let unit = 'B'

  if (value) {
    const initial = fileSizeUnit.length

    for (let i = initial; i >= 0; i--) {
      const divider = 1024 * 1024 ** i

      if (size >= divider) {
        value = size / divider

        unit = fileSizeUnit[i]

        break
      }
    }
  }

  return `${+value.toFixed(2)} ${unit}`
}

export const generatePwd = () =>
  generate({
    length: 10,
    numbers: true,
    symbols: true,
    strict: true,
    excludeSimilarCharacters: true
  })

export const getFlagUrl = (code: string) => {
  return `https://flagcdn.com/${code.toLocaleLowerCase()}.svg`
}

export const getPhone = (phone_code: string, phone_no: string) => {
  const tempArr = [phone_code, phone_no].filter(Boolean)

  if (tempArr.length) return tempArr.join(' ')

  return '-'
}

export const getDateOfBirth = (date: string) => {
  if (!date) return '-'

  return isValid(new Date(date)) ? format(date, 'dd MMM yyyy') : date
}
