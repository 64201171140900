import { GENDER_OPTIONS } from '$app/constants'
import Breadcrumb from '$components/Breadcrumb/v2'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppUserService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount } from 'ahooks'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { DEFAULT_FORM_VALUES, schema, TFormValues } from '../constants'
import Loading from '$components/Loading'
import { TAppUserPayload } from '$services/api'
import { useCallback } from 'react'
import { cn } from '$app/utils'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import Select from '$components/Select'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import { useCountryStore } from '$hooks/stores'
import UserRoles from '../Create/Form/UserRoles'
import FooterAction from '$components/FooterAction'
import { Button, Spinner } from '@genie-fintech/ui/components'
import RedirectPrompt from '$blocks/RedirectPrompt'
import { BaseText } from '@genie-fintech/ui/components/fields'

const { colors } = themeVars

const AppUserEdit = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, userId } = params

  const proxyRedirect = useRedirectProxy()

  const {
    user,
    fetchAppUserDetailAsync,
    fetchingAppUserDetail,
    updateUserAsync,
    updatingUser
  } = useAppUserService()

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors }
  } = methods

  const { replace } = useFieldArray<TFormValues>({
    name: 'roles' as never,
    control
  })

  const selectedRoles = useWatch({ name: 'roles', control })

  useMount(() => {
    if (!appId || !userId) return
    fetchAppUserDetailAsync({
      application_id: appId,
      user_id: userId
    }).then(({ data }) => {
      const { roles, gender, phone_code, phone_no, ...rest } = data

      const modifiedData: TFormValues = {
        roles: roles.map(v => v.id),
        gender: GENDER_OPTIONS.find(d => d.value === gender) ?? {
          label: '',
          value: ''
        },
        phone_code: { label: phone_code ?? '', value: phone_code ?? '' },
        phone_no: phone_no ?? '',
        ...rest
      }

      reset(modifiedData)
    })
  })

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_USER_DETAIL, { params })
  }, [proxyRedirect, params])

  const onSuccess = useCallback(() => {
    redirect(ROUTE_NAMES.APP_USER_DETAIL, { params })
  }, [params])

  const onSubmit = handleSubmit(
    ({ gender, phone_code, status, ...rest }: TFormValues) => {
      if (!appId || !userId) return

      const payload: TAppUserPayload = {
        gender: gender.value,
        phone_code: phone_code.value,
        status: userId ? status : undefined,
        ...rest
      }

      return updateUserAsync(appId, userId, payload).then(onSuccess)
    }
  )

  if (fetchingAppUserDetail || !user) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_USERS },
          { name: 'User Details' }
        ]}
      />

      <form
        className="flex flex-col max-w-[1056px] mx-auto gap-2"
        onSubmit={onSubmit}
      >
        <article
          className={cn(
            'flex flex-col rounded-lg',
            defaultBackground,
            defaultBorder
          )}
        >
          <header
            className="flex items-center px-12 py-3 border-b"
            style={{ borderColor: colors.neutral[10] }}
          >
            <p
              className="text-xl font-semibold"
              style={{ color: colors.text.light }}
            >
              Edit {user.name}
            </p>
          </header>

          <article className="flex px-12 py-7">
            <article className="grid lg:grid-cols-2 gap-8">
              <article className="flex flex-col gap-1">
                <p className="font-semibold">USER INFO</p>
                <p className="text-xs" style={{ color: colors.neutral[50] }}>
                  Access detailed information about each user to manage their
                  settings and permissions.
                </p>
              </article>

              <article className="flex flex-col gap-5">
                <BaseHookFieldText
                  control={control}
                  name="name"
                  label="User Name"
                  required
                />

                <BaseHookFieldText
                  control={control}
                  name="date_of_birth"
                  label="Date of Birth"
                  inputProps={{ type: 'date' }}
                />

                <Controller
                  name="gender"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <article className="flex flex-col gap-y-1">
                        <label className="text-sm font-medium">Gender</label>
                        <Select
                          {...field}
                          options={GENDER_OPTIONS}
                          error={!!error?.message}
                        />
                        {error?.message && (
                          <p className="text-xs text-[--colors-danger-default]">
                            {error.message}
                          </p>
                        )}
                      </article>
                    )
                  }}
                />

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => {
                    return (
                      <BaseText
                        label="Email"
                        required
                        disabled
                        inputProps={{
                          type: 'email',
                          value: field.value,
                          readOnly: true,
                          disabled: true
                        }}
                      />
                    )
                  }}
                />

                <article className="relative">
                  <BaseHookFieldText
                    control={control}
                    name="phone_no"
                    label="Phone Number"
                    inputProps={{ className: 'pl-[100px]' }}
                  />

                  <article className="absolute bottom-0 left-0 w-[100px]">
                    <Controller
                      name="phone_code"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            value={field.value}
                            onChange={field.onChange}
                            options={phoneOptions}
                            type="sub"
                          />
                        )
                      }}
                    />
                  </article>
                </article>

                <Textarea name="address" control={control} label="Address" />
              </article>
            </article>
          </article>
        </article>

        <UserRoles
          roles={selectedRoles}
          onChange={replace}
          errorMessage={errors.roles?.message}
          appId={appId}
        />

        <FooterAction>
          <article className="flex justify-end w-full max-w-[1056px] gap-2 mx-auto">
            <Button
              disabled={updatingUser}
              styleVariants={{ type: 'text' }}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button type="submit" disabled={!isDirty || updatingUser}>
              {updatingUser && <Spinner />}
              Save Changes
            </Button>
          </article>
        </FooterAction>
      </form>

      <RedirectPrompt
        isDirty={isDirty}
        isValid={isValid}
        type="edit"
        loading={updatingUser}
        onConfirm={onSubmit}
      />
    </>
  )
}

export default AppUserEdit
