import { cn } from '$app/utils'
import ActiveInactiveStatusBadge from '$components/ActiveInactiveStatusBadge'
import Loading from '$components/Loading'
import NewTabRedirect from '$components/NewTabRedirect'
import Pagination from '$components/Pagination'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { useAppGroupService } from '$hooks/services'
import { ROUTE_NAMES } from '$router/config'
import { TAppGroupUserResponse } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead,
  defaultBackground,
  defaultBorder
} from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { useEffect } from 'react'

const columns: {
  key: keyof Omit<TAppGroupUserResponse['data'][number], 'id'> | 'action'
  value: string
}[] = [
  { key: 'name', value: 'USERNAME' },
  { key: 'email', value: 'EMAIL' },
  { key: 'status', value: 'STATUS' },
  { key: 'role_count', value: 'ROLE' },
  { key: 'action', value: '' }
]

const GroupUsers = ({ groupName }: { groupName: string }) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, groupId } = params

  const {
    fetchAppGroupUser,
    fetchingAppGroupUser,
    groupUsers: { list, meta }
  } = useAppGroupService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId || !groupId) return
    fetchAppGroupUser({
      page,
      per_page: perPage,
      application_id: appId,
      group_id: groupId,
      q: debouncedSearchValue
    })
  }, [appId, groupId, fetchAppGroupUser, page, perPage, debouncedSearchValue])

  return (
    <article
      className={cn(
        'flex flex-col rounded-lg gap-6 px-12 py-7',
        defaultBackground,
        defaultBorder
      )}
    >
      <article className="flex flex-col gap-1">
        <p className="font-semibold">{groupName}'s USERS</p>
        <p className="text-xs text-[--colors-neutral-50]">
          Define and assign roles to control access and permissions within the
          system.
        </p>
      </article>
      <article className="flex flex-col gap-2">
        <article className="flex gap-2 items-center justify-between">
          <article>
            <BaseText
              affix={{
                pre: <Icon namespace="Search" width={16} />,
                post: searchValue ? (
                  <button onClick={() => updateSearchValue('')}>
                    <Icon namespace="Cross" width={18} />
                  </button>
                ) : undefined
              }}
              inputProps={{
                type: 'text',
                value: searchValue,
                onChange: e => updateSearchValue(e.currentTarget.value),
                placeholder: 'Search here...'
              }}
            />
          </article>

          {!!meta?.total && (
            <p className="text-[--colors-text-disabled] text-sm font-medium">
              {meta.total} USER{meta.total > 1 && 'S'}
            </p>
          )}
        </article>

        <article className={customTableContainer}>
          <main className={tableContainerInner}>
            <table className={table({ separator: 'line' })}>
              <thead className={customTableHead}>
                <tr className={tableRow}>
                  <th className={customTableCell} style={{ width: 0 }}>
                    NO
                  </th>
                  {columns.map((col, key) => (
                    <th key={key} className={customTableCell}>
                      {col.value}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody className={tableBody}>
                {list.map((data, rowKey) => (
                  <tr key={rowKey} className={tableRow}>
                    <td className={customTableCell}>
                      {(page - 1) * perPage + rowKey + 1}
                    </td>
                    {columns.map((col, colKey) => (
                      <td key={colKey} className={customTableCell}>
                        {(() => {
                          if (col.key === 'status') {
                            return (
                              <ActiveInactiveStatusBadge status={data.status} />
                            )
                          }

                          if (col.key === 'action') {
                            return (
                              <NewTabRedirect
                                name={ROUTE_NAMES.APP_USER_DETAIL}
                                options={{
                                  params: { ...params, userId: data.id }
                                }}
                              >
                                <Button
                                  styleVariants={{
                                    type: 'text',
                                    category: 'icon',
                                    kind: 'neutral'
                                  }}
                                >
                                  <span className="-rotate-45">
                                    <Icon namespace="Forward" width={18} />
                                  </span>
                                </Button>
                              </NewTabRedirect>
                            )
                          }

                          return data[col.key] ?? 'N/A'
                        })()}
                      </td>
                    ))}
                  </tr>
                ))}

                {!list.length && !fetchingAppGroupUser && (
                  <tr className={tableRow}>
                    <td
                      colSpan={columns.length + 1}
                      style={{ textAlign: 'center' }}
                      className={customTableCell}
                    >
                      NO DATA
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {fetchingAppGroupUser && <Loading />}
          </main>
        </article>
      </article>

      {!!list.length && (
        <footer className="flex items-center gap-2 justify-between bg-[--colors-area-high] p-4">
          <Pagination meta={meta} pagerProps={pagerProps} />
        </footer>
      )}
    </article>
  )
}

export default GroupUsers
