import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppGroupService } from '$hooks/services'
import { useMount } from 'ahooks'
import GroupInfo from './GroupInfo'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import DeleteWithConfirmInput from '$components/DeleteWithConfirmInput'
import GroupRoles from './GroupRoles'
import GroupUsers from './GroupUsers'
import Breadcrumb from '$components/Breadcrumb/v2'
import { ROUTE_NAMES } from '$router/config'

const AppGroupDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { groupId, appId } = params

  const { group, fetchAppGroupDetail, fetchingAppGroupDetail } =
    useAppGroupService()

  useMount(() => {
    if (!appId || !groupId) return
    fetchAppGroupDetail({ application_id: appId, group_id: groupId })
  })

  if (fetchingAppGroupDetail || !group) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: `App Details`, path: ROUTE_NAMES.APP_GROUPS },
          { name: `Group Details` }
        ]}
      />

      <article className="flex flex-col gap-2">
        <GroupInfo group={group} />

        <GroupRoles groupName={group.name} />

        <GroupUsers groupName={group.name} />

        <DangerZone>
          <DeleteWithConfirmInput type="group" name={group.name} />
        </DangerZone>
      </article>
    </>
  )
}

export default AppGroupDetail
