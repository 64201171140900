import { cn } from '$app/utils'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { FC, PropsWithChildren } from 'react'

const DangerZone: FC<PropsWithChildren> = ({ children }) => {
  return (
    <article
      className={cn(
        'flex flex-col px-12 py-7 rounded-lg gap-6',
        defaultBackground,
        defaultBorder
      )}
    >
      <label className="font-semibold">DANGER ZONE</label>

      {children}
    </article>
  )
}

export default DangerZone
