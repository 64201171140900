import { GroupIcon, RoleIcon, UserIcon } from '$assets/svg'
import PublishUnpublishBadge from '$components/PublishUnpublishBadge'
import PublishUnpublishButton from '$components/PublishUnpublishButton'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppHomeService } from '$hooks/services'
import { useAppDetailStore } from '$hooks/stores'
import { redirect, ROUTE_NAMES } from '$router/config'
import { Button } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'
import { format, isSameDay } from 'date-fns'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { Dot } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { getDailyActiveUserOption, getLast30minsOption } from './constants'
import LoginDevices from '$components/LoginDevices'
import Breadcrumb from '$components/Breadcrumb/v2'
import { cn } from '$app/utils'
import { defaultBackground, defaultBorder } from '$styles/common.css'

const Card = ({
  appName,
  type,
  count,
  desc
}: {
  appName: string
  type: 'user' | 'group' | 'role'
  count: number
  desc: string
}) => {
  const {
    route: { params }
  } = useRouteSummary()

  const handleOnClickViewAll = useCallback(() => {
    const path: ROUTE_NAMES = (() => {
      switch (type) {
        case 'user':
          return ROUTE_NAMES.APP_USERS
        case 'group':
          return ROUTE_NAMES.APP_GROUPS
        case 'role':
          return ROUTE_NAMES.APP_ROLES
        default:
          return ROUTE_NAMES.APPS
      }
    })()

    redirect(path, { params })
  }, [type, params])

  const Icon = (() => {
    switch (type) {
      case 'user':
        return <UserIcon />
      case 'group':
        return <GroupIcon />
      case 'role':
        return <RoleIcon />
      default:
        return null
    }
  })()

  return (
    <article
      className={cn(
        'flex flex-1 relative flex-col rounded-lg',
        defaultBackground,
        defaultBorder
      )}
    >
      <article className="flex flex-col gap-y-2 p-5">
        <article className="w-10 h-10 rounded-full bg-[--colors-area-low] grid place-items-center">
          {Icon}
        </article>

        <p className="font-semibold text-sm text-[--colors-text-light]">
          TOTAL {appName} {type.toLocaleUpperCase()}S
        </p>

        <p className="font-semibold text-[32px] text-[--colors-text-light]">
          {count}
        </p>

        <p className="text-xs text-[--colors-neutral-50]">{desc}</p>
      </article>

      <article className="flex justify-end items-center px-5 py-3 bg-[--colors-alphaNeutral-0]">
        <Button
          onClick={handleOnClickViewAll}
          styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
        >
          View All
        </Button>
      </article>
    </article>
  )
}

const AppHome = () => {
  const { name: appName = '', is_published } =
    useAppDetailStore(state => state.appDetail) ?? {}

  const [startDate, setStartDate] = useState(new Date())

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const { fetchAppHome, appHome } = useAppHomeService()

  const {
    user_count = 0,
    group_count = 0,
    role_count = 0,
    today_active_user_count = 0,
    recent_active_users = [],
    active_users = []
  } = appHome ?? {}

  useEffect(() => {
    if (!appId) return
    fetchAppHome({
      application_id: appId,
      range: 6,
      date: format(startDate, 'yyyy-MM-dd')
    })
  }, [appId, fetchAppHome, startDate])

  const handleOnSelectWeek = useCallback(
    (weekType: 'this_week' | 'last_week') => {
      const date = new Date()

      if (weekType === 'this_week') {
        setStartDate(date)
        return
      }

      if (weekType === 'last_week') {
        date.setDate(date.getDate() - 7)
        setStartDate(date)
        return
      }
    },
    []
  )

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[{ name: 'App Details' }]}
      />

      <section className="flex-1 flex flex-col gap-2">
        <article
          className={cn(
            'flex flex-wrap items-center p-6 gap-4 rounded-lg bg-[--colors-area-low]',
            defaultBorder
          )}
        >
          <Icon namespace="Lock" color="text.disabled" />
          <article className="grow flex flex-col gap-y-1">
            <p className="font-semibold">PUBLISH APPLICATION SETTING</p>
            <p className="text-xs text-[--colors-neutral-50]">
              Publish application settings to manage and configure system
              preferences efficiently.
            </p>
          </article>
          <article className="flex items-center justify-end gap-x-2">
            <PublishUnpublishBadge is_published={is_published ?? false} />
            <PublishUnpublishButton />
          </article>
        </article>

        <main className="flex-1 flex flex-col gap-2">
          <article className="grid grid-cols-1 xl:grid-cols-3 gap-2">
            <Card
              appName={appName}
              type="user"
              count={user_count}
              desc="Track the total number of users on app for comprehensive system usage insights."
            />

            <Card
              appName={appName}
              type="role"
              count={role_count}
              desc="Monitor the total number of roles in app for effective user management and permissions."
            />

            <Card
              appName={appName}
              type="group"
              count={group_count}
              desc="Review the total number of groups in app for streamlined user access control."
            />

            <article
              className={cn(
                'xl:col-span-2 grid md:grid-cols-[40%_60%] p-5 relative gap-2 rounded-lg',
                defaultBackground,
                defaultBorder
              )}
            >
              <article className="flex flex-col gap-2">
                <p className="font-semibold text-sm text-[--colors-text-light]">
                  ACTIVE USERS IN LAST 30 MINS
                </p>

                <p className="font-semibold text-[32px] text-[--colors-text-light]">
                  {recent_active_users.reduce((r, c) => r + c.length, 0)}
                </p>

                <p className="text-xs text-[--colors-neutral-50]">
                  View active users in the last 30 minutes for real-time
                  engagement insights.
                </p>

                <article className="flex-1" />

                <article className="py-5">
                  <article className="inline-flex flex-col bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1] rounded-lg">
                    <div className="flex justify-center bg-[--colors-area-low] px-4 py-2 uppercase font-semibold text-sm">
                      {format(new Date(), 'E')}
                    </div>
                    <div className="flex justify-center px-4 py-2 text-sm font-semibold text-[--colors-neutral-60]">
                      {format(new Date(), 'dd')}
                    </div>
                  </article>
                </article>
              </article>

              <article className="flex w-full">
                <div style={{ width: '100%' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={getLast30minsOption(recent_active_users)}
                  />
                </div>
              </article>
            </article>

            <LoginDevices data={recent_active_users.flatMap(v => v)} />

            <article
              className={cn(
                'xl:col-span-3 grid lg:grid-cols-[30%_70%] p-5 relative gap-2 rounded-lg',
                defaultBackground,
                defaultBorder
              )}
            >
              <article className="flex flex-col gap-2">
                <p className="font-semibold text-sm text-[--colors-text-light]">
                  APPLICATION DAILY ACTIVE USERS
                </p>

                <p className="font-semibold text-[32px] text-[--colors-text-light]">
                  {today_active_user_count}
                </p>

                <article>
                  <div className="inline-flex items-center rounded bg-[--colors-alphaPrimary-1] text-[--colors-primary-default] px-1.5 py-px gap-x-1">
                    <span className="w-1 h-1 rounded-full bg-[--colors-primary-default]" />
                    <span className="text-[--colors-primary-default] text-xs font-medium">
                      TODAY
                    </span>
                  </div>
                </article>

                <p className="text-xs text-[--colors-neutral-50]">
                  Analyze daily active users to track application engagement and
                  usage trends.
                </p>

                <article className="flex-1" />

                <article className="py-5">
                  <article className="inline-flex flex-col bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1] rounded-lg">
                    <div className="flex justify-center bg-[--colors-area-low] px-4 py-2 uppercase font-semibold text-sm">
                      {format(new Date(), 'E')}
                    </div>
                    <div className="flex justify-center px-4 py-2 text-sm font-semibold text-[--colors-neutral-60]">
                      {format(new Date(), 'dd')}
                    </div>
                  </article>
                </article>
              </article>

              <article className="flex flex-col gap-y-2 flex-1">
                <article className="flex items-center justify-end">
                  <Button
                    onClick={() => handleOnSelectWeek('last_week')}
                    styleVariants={{
                      kind: !isSameDay(startDate, new Date())
                        ? 'primary'
                        : 'neutral',
                      type: 'text'
                    }}
                    className="gap-0"
                  >
                    <Dot />
                    <span>Last Week</span>
                  </Button>

                  <Button
                    onClick={() => handleOnSelectWeek('this_week')}
                    styleVariants={{
                      kind: isSameDay(startDate, new Date())
                        ? 'primary'
                        : 'neutral',
                      type: 'text'
                    }}
                    className="gap-0"
                  >
                    <Dot />
                    <span>This Week</span>
                  </Button>
                </article>

                <article className="flex w-full h-[400px]">
                  <div style={{ width: '100%', height: '400px' }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={getDailyActiveUserOption(active_users)}
                    />
                  </div>
                </article>
              </article>
            </article>
          </article>
        </main>
      </section>
    </>
  )
}

export default AppHome
