import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppPublishUnpublishService } from '$hooks/services/useAppPublishUnpublishService'
import { useAppDetailStore } from '$hooks/stores'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { useCallback, useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { useAppBasicSettingService } from '$hooks/services'
import Switch from '$components/Switch'
import { AlertTriangle } from 'lucide-react'
import { themeVars } from '@genie-fintech/ui/style/theme'

const { colors } = themeVars

const PublishUnpublishButton = ({
  callbackFn
}: {
  callbackFn?: VoidFunction
}) => {
  const [showConfirm, setShowConfirm] = useState(false)

  const {
    route: {
      params: { appId }
    }
  } = useRouteSummary()

  const {
    fetchPublishAppAsync,
    fetchingPublishApp,
    fetchUnpublishAppAsync,
    fetchingUnpublishApp
  } = useAppPublishUnpublishService()

  const { fetchAppBasicSetting } = useAppBasicSettingService()

  const is_published =
    useAppDetailStore(state => state.appDetail?.is_published) ?? false

  const handleOnClick = useCallback(() => {
    setShowConfirm(true)
  }, [])

  const handleOnCancel = useCallback(() => {
    setShowConfirm(false)
  }, [])

  const handleOnConfirm = useCallback(() => {
    setShowConfirm(false)

    if (!appId) return

    const fn = is_published ? fetchUnpublishAppAsync : fetchPublishAppAsync

    fn.bind({ application_id: appId })

    fn({ application_id: appId }).then(() => {
      fetchAppBasicSetting({ application_id: appId })
      callbackFn?.()
    })
  }, [
    appId,
    is_published,
    fetchPublishAppAsync,
    fetchUnpublishAppAsync,
    fetchAppBasicSetting,
    callbackFn
  ])

  const isProcessing = fetchingPublishApp || fetchingUnpublishApp

  return (
    <Dialog.Root open={showConfirm}>
      <article className="w-[50px] flex items-center justify-center">
        {isProcessing ? (
          <Spinner />
        ) : (
          <Switch checked={is_published} onChange={handleOnClick} />
        )}
      </article>

      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className="DialogContent flex flex-col gap-4 max-w-[480px] p-8"
          aria-describedby=""
        >
          <Dialog.Title className="hidden" />

          <article className="flex items-center justify-between gap-2">
            <article className="inline-flex border border-[--colors-warning-20] text-[--colors-warning-default] bg-[--colors-alphaWarning-1] rounded-lg p-2.5">
              <AlertTriangle />
            </article>
          </article>

          <p className="text-xl font-semibold">
            {`Are you sure, you want to ${is_published ? 'unpublish' : 'publish'} the application?`}
          </p>

          <p className="text-sm" style={{ color: colors.neutral[70] }}>
            {`The application will be ${is_published ? 'unpublished' : 'published'}. Application users can ${is_published ? 'not' : ''} be seen or used.`}
          </p>

          <article className="flex items-center justify-end gap-2">
            <Button
              onClick={handleOnCancel}
              styleVariants={{ kind: 'neutral', type: 'outlined' }}
            >
              Cancel
            </Button>

            <Button onClick={handleOnConfirm}>Confirm</Button>
          </article>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default PublishUnpublishButton
