import { TAppListResponse } from '$services/api'
import { CopyButton } from '$components/CopyButon'
import AppLogo from '$components/AppLogo'
import { GroupIcon, RoleIcon, UserIcon } from '$assets/svg'
import { redirect, ROUTE_NAMES } from '$router/config'
import {
  APP_CARRO_LAUNCHPAD_URL,
  APP_GENIE_LAUNCHPAD_URL
} from '$services/environments'

import AppEnvironment from '$components/AppEnvironment'
import { useCallback } from 'react'
import { APP_BRANDS, TOOLTIP_ID } from '$app/constants'
import { Link } from 'lucide-react'
import { cn } from '$app/utils'
import { defaultBackground, defaultBorder } from '$styles/common.css'

interface IAppCardProps {
  data: TAppListResponse['data'][number]
}

const AppCard = ({ data }: IAppCardProps) => {
  const handleOnClickCard = useCallback(() => {
    redirect(ROUTE_NAMES.APP_HOME, { params: { appId: data.id } })
  }, [data.id])

  const LAUNCHPAD_URL =
    data.brand === APP_BRANDS[2].name
      ? APP_GENIE_LAUNCHPAD_URL
      : APP_CARRO_LAUNCHPAD_URL

  return (
    <article
      onClick={handleOnClickCard}
      className={cn(
        'flex flex-col rounded-lg hover:border-[--colors-neutral-60] duration-200 cursor-pointer',
        defaultBackground,
        defaultBorder
      )}
    >
      <article className="flex flex-col gap-y-4 px-4 pt-3 pb-2">
        <article className="flex gap-x-2 items-center">
          <AppLogo
            logo={data.logo}
            brand={data.brand}
            size="big"
            is_published={data.is_published}
          />

          <article className="flex flex-col">
            <p className="font-medium text-sm">{data.name}</p>

            <AppEnvironment environment={data.environment} />
          </article>
        </article>

        <article className="grid grid-cols-2 rounded-lg border border-[--colors-neutral-10] divide-x divide-[--colors-neutral-10] bg-[--colors-area-high] shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1]">
          <CopyButton
            classNames={{
              container: 'flex flex- 1 justify-center gap-x-2 py-2'
            }}
            value={data.client_id}
            size={14}
          >
            <p className="text-xs font-medium text-[--colors-neutral-70]">
              Client ID
            </p>
          </CopyButton>

          <CopyButton
            classNames={{
              container: 'flex flex- 1 justify-center gap-x-2 py-2'
            }}
            value={LAUNCHPAD_URL}
            size={14}
            initialIcon={Link}
          >
            <p className="text-xs font-medium text-[--colors-neutral-70]">
              URL
            </p>
          </CopyButton>
        </article>
      </article>

      <article className="grid grid-cols-3 p-2">
        <article className="flex flex-1 p-2">
          <div
            className="inline-flex items-center gap-x-2"
            data-tooltip-id={TOOLTIP_ID}
            data-tooltip-content={'Users'}
          >
            <UserIcon />
            <span className="text-sm font-medium text-[--colors-text-light]">
              {data.user_count}
            </span>
          </div>
        </article>

        <article className="flex flex-1 justify-center p-2">
          <div
            className="inline-flex items-center gap-x-2"
            data-tooltip-id={TOOLTIP_ID}
            data-tooltip-content={'Roles'}
          >
            <RoleIcon />
            <span className="text-sm font-medium text-[--colors-text-light]">
              {data.role_count}
            </span>
          </div>
        </article>

        <article className="flex flex-1 justify-end p-2">
          <div
            className="inline-flex items-center gap-x-2"
            data-tooltip-id={TOOLTIP_ID}
            data-tooltip-content={'Groups'}
          >
            <GroupIcon />
            <span className="text-sm font-medium text-[--colors-text-light]">
              {data.group_count}
            </span>
          </div>
        </article>
      </article>
    </article>
  )
}

export default AppCard
