import { cn, getPhone } from '$app/utils'
import AppLogo from '$components/AppLogo'
import Breadcrumb from '$components/Breadcrumb/v2'
import { CopyButton } from '$components/CopyButon'
import DashboardAdminMessage from '$components/DashboardAdminMessage'
import EmptyData from '$components/EmptyData'
import Loading from '$components/Loading'
import Select from '$components/Select'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useGlobalDashboardUserService } from '$hooks/services'
import { useCountryStore } from '$hooks/stores'
import { redirect, ROUTE_NAMES } from '$router/config'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { Avatar, Button } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount, useToggle } from 'ahooks'
import { format } from 'date-fns'
import { Ban, Lock, Trash } from 'lucide-react'
import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { ACCOUNT_TYPE } from '../constants'

const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  role: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  type: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  email: z.string().trim().min(1, { message: 'Required!' }).email(),
  phone_no: z.string().trim(),
  phone_code: z.object({
    value: z.string().trim(),
    label: z.string().trim()
  })
})

type TFormValues = z.infer<typeof schema>

const DEFAULT_FORM_VALUES: TFormValues = {
  name: '',
  type: { label: '', value: '' },
  role: { label: '', value: '' },
  email: '',
  phone_code: { label: '', value: '' },
  phone_no: ''
}

const Info = ({ label, value }: { label: string; value: string }) => {
  return (
    <article className="flex flex-col gap-1">
      <p className="text-xs font-medium text-[--colors-text-disabled]">
        {label}
      </p>
      <p className="text-sm font-medium">{value}</p>
    </article>
  )
}

const GlobalDashboardUserDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { dashboardUserId } = params

  const isMounted = useIsMounted()

  const [isEditMode, { setLeft: setEditFalse, setRight: setEditTrue }] =
    useToggle()

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const {
    fetchGlobalDashboardUserDetail,
    fetchingGlobalDashboardUserDetail,
    dashboardUser,
    user_roles_options,
    user_types_options,
    fetchUserRoleSelect,
    fetchUserTypeSelect,
    updateGlobalDashboardUserAsync,
    updatingGlobalDashboardUser
  } = useGlobalDashboardUserService()

  useMount(() => {
    if (!dashboardUserId) return
    fetchUserRoleSelect()
    fetchUserTypeSelect()
    fetchGlobalDashboardUserDetail(dashboardUserId)
  })

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = methods

  const onEdit = useCallback(() => {
    const { phone_code = '', role, type } = dashboardUser ?? {}
    const modifiedRole = user_roles_options.find(d => d.value === role) ?? {
      label: '',
      value: ''
    }
    const modifiedType = user_types_options.find(d => d.value === type) ?? {
      label: '',
      value: ''
    }
    reset({
      ...dashboardUser,
      phone_code: { label: phone_code, value: phone_code },
      role: modifiedRole,
      type: modifiedType
    })
    setEditTrue()
  }, [
    setEditTrue,
    dashboardUser,
    user_roles_options,
    user_types_options,
    reset
  ])

  const onCancel = useCallback(() => {
    setEditFalse()
    reset(DEFAULT_FORM_VALUES)
  }, [setEditFalse, reset])

  const onSubmit = useCallback(
    ({ type, role, phone_code, ...rest }: TFormValues) => {
      if (!dashboardUserId) return

      const payload = {
        ...rest,
        phone_code: phone_code.value,
        role: role.value,
        type: type.value
      }

      updateGlobalDashboardUserAsync(dashboardUserId, payload).then(onCancel)
    },
    [dashboardUserId, updateGlobalDashboardUserAsync, onCancel]
  )

  const onClickAppCard = useCallback((appId: number) => {
    redirect(ROUTE_NAMES.APP_HOME, { params: { appId } })
  }, [])

  const onAddApplication = useCallback(() => {
    redirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_ATTACH_APP, { params })
  }, [params])

  const onGoToAppListing = useCallback(() => {
    redirect(ROUTE_NAMES.APPS)
  }, [])

  if (fetchingGlobalDashboardUserDetail && !isMounted) return <Loading />

  if (!dashboardUser) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.GLOBAL_DASHBOARD_USERS}
        data={[{ name: 'User Details' }]}
      />

      <article className="flex-1 flex flex-col">
        <span className="fixed inset-x-0 top-0 h-[200px] bg-[url('/cover.lossless.png')] bg-no-repeat bg-fixed bg-cover" />

        <article className="flex flex-1 flex-col z-[1]">
          <article className="grid lg:grid-cols-[370px_auto] gap-2">
            <article className="flex flex-col gap-2">
              <article
                className={cn(
                  'flex flex-col gap-4 rounded-lg px-5 py-4',
                  defaultBackground,
                  defaultBorder
                )}
              >
                <article className="flex flex-col gap-2">
                  <p className="font-semibold">Profile Name</p>

                  <p className="text-xs text-[--colors-neutral-50]">
                    Your name will be visible to your coworkers on CARROsso.
                  </p>
                </article>

                <article className="flex gap-2">
                  <article>
                    <Avatar
                      status={
                        dashboardUser.status === 'active' ? 'online' : undefined
                      }
                      size={30}
                    />
                  </article>

                  <article className="flex flex-col gap-1">
                    <p className="text-sm font-medium text-[--colors-text-light]">
                      {dashboardUser.name}
                    </p>
                    <p className="text-xs text-[--colors-neutral-60]">
                      {dashboardUser.email}
                    </p>
                  </article>
                </article>

                <article
                  className={cn(
                    'flex items-center gap-2 rounded-lg p-4',
                    defaultBackground,
                    defaultBorder
                  )}
                >
                  <article className="flex flex-col gap-1 flex-1">
                    <p className="text-xs text-[--colors-neutral-70] font-medium">
                      User ID
                    </p>
                    <p className="text-[10px] text-[--colors-neutral-70]">
                      #{dashboardUser.id}
                    </p>
                  </article>

                  <CopyButton value={dashboardUser.id} size={18} />
                </article>

                <p className="text-sm font-medium text-[--colors-text-disabled]">
                  Last Signed In{' '}
                  {dashboardUser.last_login_at
                    ? format(
                        dashboardUser.last_login_at,
                        'dd MMM yyyy, hh:mm z'
                      )
                    : '-'}
                </p>
              </article>

              <article
                className={cn(
                  'flex flex-col gap-4 rounded-lg px-5 py-4',
                  defaultBackground,
                  defaultBorder
                )}
              >
                <article className="flex flex-col gap-2">
                  <p className="font-semibold">Setting</p>

                  <p className="text-xs text-[--colors-neutral-50]">
                    Your setting can be editable on CARROsso.
                  </p>
                </article>

                <article className="flex flex-col gap-1 py-2">
                  <article>
                    <Button
                      disabled
                      styleVariants={{
                        kind: 'neutral',
                        type: 'text',
                        size: 'small'
                      }}
                    >
                      <Lock size={20} />
                      Change password
                    </Button>
                  </article>

                  <hr className="border border-[--colors-neutral-10]" />

                  <article>
                    <Button
                      disabled
                      styleVariants={{
                        kind: 'danger',
                        type: 'text',
                        size: 'small'
                      }}
                    >
                      <Ban size={20} />
                      Deactivate user
                    </Button>
                  </article>

                  <hr className="border border-[--colors-neutral-10]" />

                  <article>
                    <Button
                      disabled
                      styleVariants={{
                        kind: 'danger',
                        type: 'text',
                        size: 'small'
                      }}
                    >
                      <Trash size={20} />
                      Delete user
                    </Button>
                  </article>
                </article>

                <article className="flex flex-col rounded bg-[--colors-area-low] px-4 py-3">
                  <p className="text-xs text-[--colors-text-disabled] font-medium">
                    Joined On
                  </p>
                  <p className="font-semibold text-sm">
                    {dashboardUser.last_login_at
                      ? format(
                          dashboardUser.last_login_at,
                          'dd MMM yyyy, hh:mm z'
                        )
                      : '-'}
                  </p>
                </article>
              </article>
            </article>

            <article className="flex flex-col gap-2">
              <article className="flex flex-col gap-4 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[--colors-alphaNeutral-1] shadow-[0px_1px_2px_1px] rounded-lg px-5 py-4">
                <form
                  className="grid md:grid-cols-2 gap-4"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <article className="col-span-2 flex items-center gap-2 justify-between">
                    <article className="flex flex-col gap-2">
                      <p className="font-semibold">Personal Details</p>

                      <p className="text-xs text-[--colors-neutral-50]">
                        Your personal details can be editable on CARROsso.
                      </p>
                    </article>

                    <article className="flex items-center gap-2">
                      {!isEditMode && (
                        <Button
                          styleVariants={{ kind: 'neutral', type: 'outlined' }}
                          onClick={onEdit}
                        >
                          Edit
                        </Button>
                      )}

                      {isEditMode && (
                        <>
                          <Button
                            disabled={updatingGlobalDashboardUser}
                            styleVariants={{
                              kind: 'neutral',
                              type: 'outlined'
                            }}
                            onClick={onCancel}
                          >
                            Cancel
                          </Button>

                          <Button
                            disabled={!isDirty || updatingGlobalDashboardUser}
                            type="submit"
                          >
                            Save
                          </Button>
                        </>
                      )}
                    </article>
                  </article>
                  {isEditMode ? (
                    <BaseText
                      control={control}
                      name="name"
                      label="User Name"
                      required
                    />
                  ) : (
                    <Info label="User Name" value={dashboardUser.name} />
                  )}

                  {isEditMode ? (
                    <BaseText
                      control={control}
                      name="email"
                      label="Email"
                      required
                    />
                  ) : (
                    <Info label="Email" value={dashboardUser.email} />
                  )}

                  {isEditMode ? (
                    <article className="relative">
                      <BaseText
                        control={control}
                        name="phone_no"
                        label="Phone Number"
                        inputProps={{ className: 'pl-[100px]' }}
                      />

                      <article className="absolute bottom-0 left-0 w-[100px]">
                        <Controller
                          name="phone_code"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                value={field.value}
                                onChange={field.onChange}
                                options={phoneOptions}
                                type="sub"
                              />
                            )
                          }}
                        />
                      </article>
                    </article>
                  ) : (
                    <Info
                      label="Phone Number"
                      value={getPhone(
                        dashboardUser.phone_code,
                        dashboardUser.phone_no
                      )}
                    />
                  )}

                  {isEditMode ? (
                    <Controller
                      name="role"
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <article className="flex flex-col gap-y-1">
                            <label className="text-sm font-medium">
                              Account Role
                            </label>
                            <Select
                              {...field}
                              options={user_roles_options}
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <p className="text-xs text-[--colors-danger-default]">
                                {error.message}
                              </p>
                            )}
                          </article>
                        )
                      }}
                    />
                  ) : (
                    <Info
                      label="Account Role"
                      value={
                        user_roles_options.find(
                          d => d.value === dashboardUser.role
                        )?.label ?? ''
                      }
                    />
                  )}

                  {isEditMode ? (
                    <Controller
                      name="type"
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <article className="flex flex-col gap-y-1">
                            <label className="text-sm font-medium">
                              Account Type
                            </label>
                            <Select
                              {...field}
                              options={user_types_options}
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <p className="text-xs text-[--colors-danger-default]">
                                {error.message}
                              </p>
                            )}
                          </article>
                        )
                      }}
                    />
                  ) : (
                    <Info
                      label="Account Type"
                      value={
                        user_types_options.find(
                          d => d.value === dashboardUser.type
                        )?.label ?? ''
                      }
                    />
                  )}
                </form>
              </article>

              {dashboardUser.type !== ACCOUNT_TYPE.ADMIN && (
                <article
                  className={cn(
                    'flex flex-col rounded-lg gap-6 px-6 py-4',
                    defaultBackground,
                    defaultBorder
                  )}
                >
                  <article className="flex items-end gap-1">
                    <article className="flex-1 flex flex-col gap-1">
                      <p className="font-semibold">
                        {dashboardUser.applications.length
                          ? `ACCESS ${dashboardUser.applications.length} APPLICATIONS TO MANAGE`
                          : 'NO APPLICATION TO MANAGE'}
                      </p>
                      <p className="text-xs text-[--colors-neutral-50]">
                        You have access on these applications to manage on
                        CARROsso.
                      </p>
                    </article>
                    <article>
                      <Button onClick={onAddApplication}>
                        Add Application
                      </Button>
                    </article>
                  </article>

                  <article className="grid md:grid-cols-2 gap-2">
                    {dashboardUser.applications.map((v, k) => {
                      return (
                        <button
                          onClick={() => onClickAppCard(v.id)}
                          key={k}
                          className="flex items-center gap-2 p-4 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[--colors-alphaNeutral-1] shadow-[0px_1px_2px_1px] rounded-lg"
                        >
                          <AppLogo logo={v.logo} brand={v.brand} />
                          <p className="text-sm font-medium">{v.name}</p>
                        </button>
                      )
                    })}
                  </article>

                  {!dashboardUser.applications.length && (
                    <article className="flex-1 grid place-items-center">
                      <EmptyData type="application" />
                    </article>
                  )}
                </article>
              )}

              {dashboardUser.type === ACCOUNT_TYPE.ADMIN && (
                <article
                  className={cn(
                    'flex flex-col gap-4 items-center px-6 py-4 rounded-lg',
                    defaultBackground,
                    defaultBorder
                  )}
                >
                  <DashboardAdminMessage />

                  <Button
                    styleVariants={{ type: 'text', size: 'small' }}
                    onClick={onGoToAppListing}
                  >
                    Go to Application Listing
                  </Button>
                </article>
              )}
            </article>
          </article>
        </article>
      </article>
    </>
  )
}

export default GlobalDashboardUserDetail
