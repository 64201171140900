import { ROUTE_NAMES } from '$router/config'
import { FC, PropsWithChildren, useEffect } from 'react'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppBasicSettingService } from '$hooks/services'
import { MainContainerStyle, NavContainerStyle } from './styles.css'
import SidebarToggleMenu from '$components/SideBarToggleMenu'
import Link from '$router/Link'
import { Logo } from '$assets/svg'
import { BREADCRUMB_BAR_ID } from '$app/constants'
import ThemeToggleButton from '$components/ThemeToggleButton'
import Profile from '$components/Profile'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { cn } from '$app/utils'

const { colors } = themeVars

const HIDE_NAVBAR_PAGES = [ROUTE_NAMES.LOGIN]

const PageLayout: FC<PropsWithChildren<{ removeLink?: boolean }>> = ({
  children
}) => {
  const {
    route: {
      name: routeName,
      params: { appId }
    }
  } = useRouteSummary()

  const { fetchAppBasicSetting } = useAppBasicSettingService()

  useEffect(() => {
    if (!appId) return
    fetchAppBasicSetting({ application_id: appId })
  }, [appId, fetchAppBasicSetting])

  const isHideNavbar = HIDE_NAVBAR_PAGES.includes(routeName!)

  return (
    <section className="flex flex-col min-h-screen">
      {!isHideNavbar && (
        <nav className={NavContainerStyle}>
          <SidebarToggleMenu />

          <Link to={ROUTE_NAMES.APPS}>
            <article className="inline-flex px-2 cursor-pointer">
              <Logo color="absolute.light" />
            </article>
          </Link>

          <article
            className="inline-flex h-full"
            style={{
              borderLeft: `1px solid hsl(${colors.absoluteHsl.light},0.3)`
            }}
          />

          <article id={BREADCRUMB_BAR_ID} className="hidden md:flex">
            {/* Breadcrumb bar  */}
          </article>

          <article className="flex-1" />

          <ThemeToggleButton />

          <Profile />
        </nav>
      )}

      <main className={cn(MainContainerStyle({ isFullPage: isHideNavbar }))}>
        {children}
      </main>
    </section>
  )
}

export default PageLayout
