import Breadcrumb from '$components/Breadcrumb/v2'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppPermissionService } from '$hooks/services'
import { ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import PermissionInfo from './PermissionInfo'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import DeleteWithConfirmInput from '$components/DeleteWithConfirmInput'

const AppPermissionDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, permissionId } = params

  const { permission, fetchAppPermissionDetail, fetchingAppPermissionDetail } =
    useAppPermissionService()

  useMount(() => {
    if (!appId || !permissionId) return
    fetchAppPermissionDetail({
      application_id: appId,
      permission_id: permissionId
    })
  })

  if (fetchingAppPermissionDetail || !permission) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_ROLES },
          { name: 'Permission Details' }
        ]}
      />

      <article className="flex flex-col gap-2">
        <PermissionInfo permission={permission} />

        <DangerZone>
          <DeleteWithConfirmInput type="permission" name={permission.name} />
        </DangerZone>
      </article>
    </>
  )
}

export default AppPermissionDetail
