import { themeVars } from '@genie-fintech/ui/style/theme'
import { ContainerStyle } from './styles.css'
import { z } from 'zod'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRequest } from 'ahooks'
import { postApp } from '$services/api'
import { toast } from 'sonner'
import { useRedirectProxy } from '$contexts/RouteContext/hooks'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useCallback } from 'react'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Select from '$components/Select'
import { APP_ENVIRONMENT_OPTIONS } from '$app/constants'
import DragAndDropFileUpload from '$components/DragAndDropFileUpload'
import { Button, Spinner } from '@genie-fintech/ui/components'
import Asterisk from '$components/Asterisk'
import Breadcrumb from '$components/Breadcrumb/v2'
import ErrorField from '$components/ErrorField'
import RedirectPrompt from '$blocks/RedirectPrompt'

const { colors } = themeVars

const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  environment: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  key: z.string().trim(),
  brand: z.string().trim()
})

type TFormValues = z.infer<typeof schema>

const DEFAULT_FORM_VALUES: TFormValues = {
  name: '',
  environment: {
    label: '',
    value: ''
  },
  key: '',
  brand: 'captain'
}
const AppCreate = () => {
  const proxyRedirect = useRedirectProxy()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty }
  } = useForm<TFormValues>({
    resolver: zodResolver(schema),
    mode: 'all',
    defaultValues: DEFAULT_FORM_VALUES
  })

  const { run: saveApp, loading: savingApp } = useRequest(postApp, {
    manual: true,
    onSuccess: ({ data: { id: appId } }) => {
      toast.success(`You've created app successfully!`)
      if (!appId) {
        window.location.reload()
        return
      }
      redirect(ROUTE_NAMES.APP_BASIC_SETTING, { params: { appId } })
    }
  })

  const brand = useWatch({ name: 'brand', control })

  const fileKey = useWatch({ name: 'key', control })

  const handleCreate = useCallback(
    ({ key, environment, ...payload }: TFormValues) => {
      saveApp({
        ...payload,
        logo: { key },
        environment: +environment.value
      })
    },
    [saveApp]
  )

  const handleBack = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APPS)
  }, [proxyRedirect])

  const handleOnUploadLogo = useCallback(
    (key: string) => {
      setValue('key', key, { shouldDirty: true })
    },
    [setValue]
  )

  const handleOnChangeBrand = useCallback(
    (brand: string) => {
      setValue('brand', brand, { shouldDirty: true })
    },
    [setValue]
  )

  return (
    <>
      <Breadcrumb category={ROUTE_NAMES.APPS} />

      <article className={ContainerStyle}>
        <article className="flex flex-col gap-1">
          <p className="font-semibold"> Create Application </p>

          <p className="text-xs" style={{ color: colors.neutral[50] }}>
            You can explore the data table format by downloading this template.
          </p>
        </article>

        <form className="flex flex-col" onSubmit={handleSubmit(handleCreate)}>
          <article className="flex flex-col px-3 py-2">
            <BaseText
              control={control}
              name="name"
              label="Application Name"
              required
            />
          </article>

          <article className="flex flex-col px-3 py-2">
            <Controller
              name="environment"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <article className="flex flex-col gap-y-1">
                    <label className="text-sm font-medium">
                      Environment
                      <Asterisk />
                    </label>
                    <Select
                      {...field}
                      options={APP_ENVIRONMENT_OPTIONS.map(v => ({
                        label: v.value.toLocaleUpperCase(),
                        value: `${v.key}`
                      }))}
                      error={!!error?.message}
                    />
                    <ErrorField text={error?.message} />
                  </article>
                )
              }}
            />
          </article>

          <article className="flex px-3 py-2">
            <DragAndDropFileUpload
              brand={brand}
              logo={{ key: fileKey }}
              onUploadLogo={handleOnUploadLogo}
              onChangeBrand={handleOnChangeBrand}
            />
          </article>

          <article className="flex justify-between items-center gap-x-2 px-3 py-2">
            <Button
              styleVariants={{ type: 'outlined' }}
              onClick={handleBack}
              disabled={savingApp}
            >
              Back to Listing
            </Button>

            <Button type="submit" disabled={savingApp}>
              {savingApp && <Spinner />}
              Create
            </Button>
          </article>
        </form>
      </article>

      <RedirectPrompt isDirty={isDirty} type="create" />
    </>
  )
}

export default AppCreate
