import { cn, generatePwd } from '$app/utils'
import Asterisk from '$components/Asterisk'
import { CopyButton } from '$components/CopyButon'
import PasswordPolicyChecker from '$components/PasswordPolicyChecker'
import { TPasswordPolicy } from '$services/api'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { BaseText, Password } from '@genie-fintech/ui/components/fields'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useMount } from 'ahooks'
import { useCallback, useState } from 'react'

const { colors } = themeVars

interface IProps {
  value: string
  onChange: (pwd: string) => void
  passwordPolicies: TPasswordPolicy[]
  error?: string
}

const PasswordManagement = ({
  value,
  onChange,
  passwordPolicies,
  error
}: IProps) => {
  const [isSystemGenerated, setIsSystemGenerated] = useState(true)

  const onRegenerate = useCallback(() => {
    const newPwd = generatePwd()
    onChange(newPwd)
  }, [onChange])

  const onCreateOwn = useCallback(() => {
    onChange('')
    setIsSystemGenerated(false)
  }, [onChange])

  const onBack = useCallback(() => {
    setIsSystemGenerated(true)
    onRegenerate()
  }, [onRegenerate])

  useMount(() => {
    if (value) return
    onRegenerate()
  })

  return (
    <article
      className={cn(
        'flex flex-col px-12 py-7 rounded-lg',
        defaultBackground,
        defaultBorder
      )}
    >
      <article className={cn('grid md:grid-cols-2 gap-16')}>
        <article className="flex flex-col gap-1">
          <p className="font-semibold">PASSWORD</p>
          <p className="text-xs" style={{ color: colors.neutral[50] }}>
            Generate or create your password to maintain account security and
            access control.
          </p>
        </article>

        <article className="flex flex-col gap-6">
          <article className="grid grid-cols-[1fr_auto] gap-2">
            <label className="text-sm font-medium col-span-2">
              {isSystemGenerated
                ? 'System Generated Strong Password'
                : 'Create Own Password'}
              <Asterisk />
            </label>

            {!isSystemGenerated && (
              <Password
                inputProps={{
                  value,
                  onChange: e => onChange(e.currentTarget.value),
                  autoComplete: 'new-password'
                }}
                error={!!error}
                message={error}
              />
            )}

            {isSystemGenerated && (
              <BaseText
                inputProps={{
                  value,
                  onChange: e => onChange(e.currentTarget.value),
                  autoComplete: 'new-password'
                }}
                error={!!error}
                message={error}
              />
            )}

            <CopyButton value={value} />

            {isSystemGenerated && (
              <>
                <Button onClick={onRegenerate}>Regenerate</Button>

                <span />

                <Button
                  styleVariants={{ kind: 'neutral', type: 'outlined' }}
                  onClick={onCreateOwn}
                >
                  Create my own password
                </Button>
              </>
            )}

            {!isSystemGenerated && (
              <Button
                styleVariants={{ kind: 'neutral', type: 'outlined' }}
                onClick={onBack}
              >
                Back
              </Button>
            )}
          </article>

          <PasswordPolicyChecker pwd={value} policies={passwordPolicies} />
        </article>
      </article>
    </article>
  )
}

export default PasswordManagement
