import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppRoleService } from '$hooks/services'
import { useMount } from 'ahooks'
import RoleInfo from './RoleInfo'
import Loading from '$components/Loading'
import RolePermissions from './RolePermissions'
import DangerZone from '$components/DangerZone'
import DeleteWithConfirmInput from '$components/DeleteWithConfirmInput'
import Breadcrumb from '$components/Breadcrumb/v2'
import { ROUTE_NAMES } from '$router/config'

const AppRoleDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { roleId, appId } = params

  const { role, fetchingAppRoleDetail, fetchAppRoleDetailAsync } =
    useAppRoleService()

  useMount(() => {
    if (!appId || !roleId) return
    fetchAppRoleDetailAsync({ application_id: appId, role_id: roleId })
  })

  if (fetchingAppRoleDetail || !role) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_ROLES },
          { name: 'Role Details' }
        ]}
      />

      <article className="flex flex-col gap-2">
        <RoleInfo role={role} />

        <RolePermissions permissions={role.permissions} />

        <DangerZone>
          <DeleteWithConfirmInput type="role" name={role.name} />
        </DangerZone>
      </article>
    </>
  )
}

export default AppRoleDetail
