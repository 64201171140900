import { FC } from 'react'
import { on, ROUTE_NAMES } from './config'
import { useRoute } from '$app/contexts/RouteContext/hooks'
import Login from '$pages/Login'
import PageLayout from '$layouts/PageLayout'
import Navigate from './Navigate'
import AppList from '$pages/AppList'
import LayoutWithSidebar from '$layouts/LayoutWithSidebar'
import AppHome from '$pages/AppHome'
import AppBasicSetting from '$pages/AppBasicSetting'
import AppAdvancedSetting from '$pages/AppAdvancedSetting'
import AppLaunchPadSetting from '$pages/AppLaunchPadSetting'
import AppTokenSetting from '$pages/AppTokenSetting'
import AppGroupList from '$pages/AppGroup/List'
import AppGroupCreate from '$pages/AppGroup/Create'
import AppGroupEdit from '$pages/AppGroup/Edit'
import AppRoleList from '$pages/AppRole/List'
import AppRoleCreate from '$pages/AppRole/Create'
import AppRoleEdit from '$pages/AppRole/Edit'
import AppUserList from '$pages/AppUser/List'
import AppPermissionList from '$pages/AppPermission/List'
import AppPermissionCreate from '$pages/AppPermission/Create'
import AppPermissionEdit from '$pages/AppPermission/Edit'
import AppUserCreate from '$pages/AppUser/Create'
import AppUserEdit from '$pages/AppUser/Edit'
import AppRoleCSVImport from '$pages/AppRoleCSVImport'
import PasswordPolicy from '$pages/PasswordPolicy'
import AppPermissionCSVImport from '$pages/AppPermissionCSVImport'
import AppUserCSVImport from '$pages/AppUserCSVImport'
import CountryList from '$pages/Country/List'
import CountryCreate from '$pages/Country/Create'
import CountryEdit from '$pages/Country/Edit'
import GlobalAppUser from '$pages/GlobalAppUser/List'
import GlobalAppUserDetail from '$pages/GlobalAppUser/Detail'
import GlobalDashboardUser from '$pages/GlobalDashboardUser/List'
import GlobalDashboardUserDetail from '$pages/GlobalDashboardUser/Detail'
import GlobalDashboardUserCreate from '$pages/GlobalDashboardUser/Create'
import AppCreate from '$pages/AppCreate'
import AppGroupDetail from '$pages/AppGroup/Detail'
import AppRoleDetail from '$pages/AppRole/Detail'
import AppPermissionDetail from '$pages/AppPermission/Detail'
import AppUserDetail from '$pages/AppUser/Detail'
import AppUserUpdatePassword from '$pages/AppUserUpdatePassword'
import GlobalUserAddApplication from '$pages/GlobalAppUser/AddApplication'
import GlobalDashboardAddApplication from '$pages/GlobalDashboardUser/AddApplication'

export const Router: FC = () => {
  const { is404 } = useRoute()

  if (is404) return '404'

  return (
    <PageLayout>
      {on(ROUTE_NAMES.LOGIN) && <Login />}

      {on(ROUTE_NAMES.INDEX) && <Navigate to={ROUTE_NAMES.APPS} replace />}

      {on(ROUTE_NAMES.GLOBAL_SETTING) && (
        <Navigate to={ROUTE_NAMES.PASSWORD_POLICY} replace />
      )}

      {on(ROUTE_NAMES.APPS) && <AppList />}

      {on(ROUTE_NAMES.APP_CREATE) && <AppCreate />}

      {on(ROUTE_NAMES.APP_HOME) && (
        <LayoutWithSidebar>
          <AppHome />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_BASIC_SETTING) && (
        <LayoutWithSidebar>
          <AppBasicSetting />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_ADVANCED_SETTING) && (
        <LayoutWithSidebar>
          <AppAdvancedSetting />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_LAUNCHPAD_SETTING) && (
        <LayoutWithSidebar>
          <AppLaunchPadSetting />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_TOKEN_SETTING) && (
        <LayoutWithSidebar>
          <AppTokenSetting />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_GROUPS) && (
        <LayoutWithSidebar>
          <AppGroupList />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_GROUP_DETAIL) && (
        <LayoutWithSidebar>
          <AppGroupDetail />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_GROUP_CREATE) && <AppGroupCreate />}

      {on(ROUTE_NAMES.APP_GROUP_EDIT) && <AppGroupEdit />}

      {on(ROUTE_NAMES.APP_ROLES) && (
        <LayoutWithSidebar>
          <AppRoleList />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_ROLE_DETAIL) && (
        <LayoutWithSidebar>
          <AppRoleDetail />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_ROLE_CREATE) && <AppRoleCreate />}

      {on(ROUTE_NAMES.APP_ROLE_EDIT) && <AppRoleEdit />}

      {on(ROUTE_NAMES.APP_USERS) && (
        <LayoutWithSidebar>
          <AppUserList />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_USER_DETAIL) && (
        <LayoutWithSidebar>
          <AppUserDetail />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_USER_UPDATE_PASSWORD) && <AppUserUpdatePassword />}

      {on(ROUTE_NAMES.APP_USER_CREATE) && <AppUserCreate />}

      {on(ROUTE_NAMES.APP_USER_EDIT) && <AppUserEdit />}

      {on(ROUTE_NAMES.APP_PERMISSIONS) && (
        <LayoutWithSidebar>
          <AppPermissionList />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_PERMISSION_DETAIL) && (
        <LayoutWithSidebar>
          <AppPermissionDetail />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_PERMISSION_CREATE) && <AppPermissionCreate />}

      {on(ROUTE_NAMES.APP_PERMISSION_EDIT) && <AppPermissionEdit />}

      {on(ROUTE_NAMES.PASSWORD_POLICY) && (
        <LayoutWithSidebar>
          <PasswordPolicy />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.COUNTRIES) && (
        <LayoutWithSidebar>
          <CountryList />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.COUNTRY_CREATE) && (
        <LayoutWithSidebar>
          <CountryCreate />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.COUNTRY_EDIT) && (
        <LayoutWithSidebar>
          <CountryEdit />
        </LayoutWithSidebar>
      )}

      {on(ROUTE_NAMES.APP_ROLES_CSV_IMPORT) && <AppRoleCSVImport />}

      {on(ROUTE_NAMES.APP_PERMISSIONS_CSV_IMPORT) && <AppPermissionCSVImport />}

      {on(ROUTE_NAMES.APP_USERS_CSV_IMPORT) && <AppUserCSVImport />}

      {on(ROUTE_NAMES.GLOBAL_APP_USERS) && <GlobalAppUser />}

      {on(ROUTE_NAMES.GLOBAL_APP_USER_DETAIL) && <GlobalAppUserDetail />}

      {on(ROUTE_NAMES.GLOBAL_APP_USER_ATTACH_APP) && (
        <GlobalUserAddApplication />
      )}

      {on(ROUTE_NAMES.GLOBAL_DASHBOARD_USERS) && <GlobalDashboardUser />}

      {on(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_CREATE) && (
        <GlobalDashboardUserCreate />
      )}

      {on(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL) && (
        <GlobalDashboardUserDetail />
      )}

      {on(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_ATTACH_APP) && (
        <GlobalDashboardAddApplication />
      )}

      {/* MORE OTHER ROUTES  */}
    </PageLayout>
  )
}
